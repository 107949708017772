<template>
    <div id="courseIntroductionDetails">
        <div class="box">
            <div class="breadCrumbs">
                <span style="color: #999999;cursor: pointer;" @click="indexClick">首页</span>>
                <span style="color: #999999;cursor: pointer;" @click="courseClick">课程教学</span>>
                <span style="color: #999999;cursor: pointer;" @click="detailsClick">课程介绍</span>>
                <span style="color: #999999;cursor: pointer;" @click="DetClick">课程详情</span>>
                <span>课后习题</span>
            </div>
            <!-- <div class="titleSystemClass">智慧工地考核系统</div> -->
            <div class="titleSystemClass">
                <p style="text-align: center;font-weight: 800;font-size: 18px;padding-top: 10px;">{{assessment.exercisesnName}}课后习题</p>
            <p style="padding-top: 5px;padding-left:10px;margin-top:10px;border-top: 1px solid #ccc;">
                <span style="padding-right: 20px;">类型:课后习题</span>
                <!-- <span style="padding-right: 20px;">系统名称:{{ trainingName?trainingName:'--' }}</span> -->
                <span style="padding-right: 20px;">作业名称:{{ exercisesnName?exercisesnName:'--' }}</span>
            </p>
            </div>
            <div class="system">
                <div style="width: 23%;background-color:#fff;">
                    <p style="padding: 20px;">
                        <img src="@/assets/img/icon/datika.png" alt="" srcset="" style="vertical-align: middle;">
                        <span style="padding-left: 15px;font-weight: 800;">答题卡</span>
                    </p>
                    <div style="font-size: 14px;padding-left:20px;padding-top: 10px;" v-if="Fillintheblanks.length != 0">填空题</div>
                    <div v-if="Fillintheblanks.length != 0" style="margin-left:20px;display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-wrap: wrap;">
                        <div style="line-height: 35px;"
                            v-for="(item, index) in Fillintheblanks" :key="index">
                            <span style="color: #76C8F6;float: left;">{{ index+=1 }}</span>
                            <span
                                style="min-width: 50px;height: 35px;background-color: #F7F8FA;display: block;float: left;margin: 10px;font-size:10px;"
                                v-for="(item2, index2) in item.answerList" :key="index2">{{ item2.dev }}</span>
                        </div>
                    </div>
                    <!--  -->
                    <div style="">
                        <div style="font-size: 14px;padding-left:20px;padding-top: 10px;" v-if="SingleSelection.length != 0">单选题</div>
                        <ul style="margin-left:20px;display: flex; align-items: center; justify-content: flex-start; flex-wrap: wrap;" v-if="SingleSelection.length != 0">
                            <li style="width: 50px;height: 40px;background-color: #F7F8FA;float: left;margin-right: 10px;border-radius: 5px;"
                                v-for="(item, index) in SingleSelection" :key="index">
                                <span style="display: block;height: 50%;background: #76C8F6;color: #fff;text-align: center;">{{ index+=1 }}</span>
                                <span style="display: block;height: 50%;color: #76C8F6;text-align: center;">
                                    {{item.dev == 1?'A':item.dev == 2?'B':item.dev == 3?'C':item.dev == 4?'D':''}}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <!--  -->
                    <div style="">
                        <div style="font-size: 14px;padding-left:20px;padding-top: 10px;" v-if="Multiple.length != 0">多选题</div>
                        <ul style="margin-left:20px;display: flex; align-items: center; justify-content: flex-start; flex-wrap: wrap;" v-if="Multiple.length != 0">
                            <li style="width: 80px;height: 40px;background-color: #F7F8FA;float: left;margin-right: 10px;"
                                v-for="(item, index) in Multiple" :key="index">
                                <span
                                    style="display: block;height: 50%;background: #76C8F6;color: #fff;text-align: center;">{{ index+=1 }}</span>
                                <span style="display: block;height: 50%;color: #76C8F6;text-align: center;">
                                    {{item.dev[0] == 1?'A':item.dev[0] == 2?'B':item.dev[0] == 3?'C':item.dev[0] == 4?'D':''}}
                                    {{item.dev[1] == 1?'A':item.dev[1] == 2?'B':item.dev[1] == 3?'C':item.dev[1] == 4?'D':''}}
                                    {{item.dev[2] == 1?'A':item.dev[2] == 2?'B':item.dev[2] == 3?'C':item.dev[2] == 4?'D':''}}
                                    {{item.dev[3] == 1?'A':item.dev[3] == 2?'B':item.dev[3] == 3?'C':item.dev[3] == 4?'D':''}}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <!--  -->
                    <div style="">
                        <div style="font-size: 14px;padding-left:20px;padding-top: 10px;" v-if="Judgment.length != 0">判断题</div>
                        <ul style="margin-left:20px;display: flex; align-items: center; justify-content: flex-start; flex-wrap: wrap;" v-if="Judgment.length != 0">
                            <li style="width: 50px;height: 40px;background-color: #F7F8FA;float: left;margin-right: 10px;"
                                v-for="(item, index) in Judgment" :key="index">
                                <span
                                    style="display: block;height: 50%;background: #76C8F6;color: #fff;text-align: center;">{{ index+=1 }}</span>
                                <span style="display: block;height: 50%;color: #76C8F6;text-align: center;">
                                    {{item.dev == 1?'A':item.dev == 2?'B':item.dev == 3?'C':item.dev == 4?'D':''}}
                                </span>
                            </li>
                        </ul>
                    </div>



                </div>
                <div style="width: 60%;height: 700px;overflow-y: auto;">
                    <!--  -->
                    <p style="font-size: 16px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    font-weight: 400;
                    color: #333333;" v-if="Fillintheblanks.length != 0">一、填空题</p>
                    <div v-if="Fillintheblanks.length != 0">
                        <div style="background-color: #fff;margin-top: 10px;padding: 10px;"
                            v-for="(item, index) in Fillintheblanks" :key="index">
                            <p style="font-size: 14px;">
                                <span
                                    style="background:#76C8F6;color: #fff;display:inline-block;width: 2%;text-align: center;border-radius: 3px;">{{ index+=1 }}</span>
                                <span style="padding-left: 10px;color: 14px;">{{ item.testQuestionContent }}</span>
                            </p>
                            <el-input placeholder="" size="mini" style="margin-top: 10px;"
                                v-for="(item2, index2) in item.answerList" :key="index2" v-model="item2.dev">
                                <template #prepend>{{ index2+=1 }}</template>
                            </el-input>
                        </div>
                    </div>
                    <!--  -->
                    <p style="font-size: 16px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    font-weight: 400;
                    color: #333333;padding-top: 10px;" v-if="SingleSelection.length != 0">二、单选题</p>
                    <div v-if="SingleSelection.length != 0">
                        <div style="background-color: #fff;margin-top: 10px;padding: 10px;"
                            v-for="(item, index) in SingleSelection" :key="index">
                            <p style="font-size: 14px;">
                                <span
                                    style="background:#76C8F6;color: #fff;display:inline-block;width: 2%;text-align: center;border-radius: 3px;">{{ index+=1 }}</span>
                                <span style="padding-left: 10px;color: 14px;">{{ item.testQuestionContent }}</span>
                            </p>
                            <el-radio-group v-model="item.dev" style="margin-top: 10px;">
                                <el-radio v-for="(item2, index2) in item.answerList" :key="index2" :label="item2.isOrder">
                                   {{item2.isOrder == 1?'A':item2.isOrder == 2?'B':item2.isOrder == 3?'C':item2.isOrder == 4?'D':''}}  {{ item2.content }}
                                </el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <!--  -->
                    <p style="font-size: 16px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    font-weight: 400;
                    color: #333333;padding-top: 10px;" v-if="Multiple.length != 0">三、多选题</p>
                    <div v-if="Multiple.length != 0">
                        <div style="background-color: #fff;margin-top: 10px;padding: 10px;" v-for="(item, index) in Multiple"
                            :key="index">
                            <p style="font-size: 14px;">
                                <span
                                    style="background:#76C8F6;color: #fff;display:inline-block;width: 2%;text-align: center;border-radius: 3px;">{{ index+=1 }}</span>
                                <span style="padding-left: 10px;color: 14px;">{{ item.testQuestionContent }}</span>
                            </p>
                            <el-checkbox-group v-model="item.dev">
                                <el-checkbox v-for="(item2, index2) in item.answerList" :key="index2" :label="item2.isOrder">
                                    {{item2.isOrder == 1?'A':item2.isOrder == 2?'B':item2.isOrder == 3?'C':item2.isOrder == 4?'D':''}}  {{ item2.content }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                    <!--  -->
                    <p style="font-size: 16px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    font-weight: 400;
                    color: #333333;padding-top: 10px;" v-if="Judgment.length != 0">四、判断题</p>
                    <div v-if="Judgment.length != 0">
                        <div style="background-color: #fff;margin-top: 10px;padding: 10px;" v-for="(item, index) in Judgment"
                            :key="index">
                            <p style="font-size: 14px;">
                                <span
                                    style="background:#76C8F6;color: #fff;display:inline-block;width: 2%;text-align: center;border-radius: 3px;">{{ index+=1 }}</span>
                                <span style="padding-left: 10px;color: 14px;">{{ item.testQuestionContent }}</span>
                            </p>
                            <el-radio-group v-model="item.dev" style="margin-top: 10px;">
                                <el-radio v-for="(item2, index2) in item.answerList" :key="index2"
                                    :label="item2.isOrder">{{item2.isOrder == 1?'A':item2.isOrder == 2?'B':item2.isOrder == 3?'C':item2.isOrder == 4?'D':''}}  {{ item2.content }}</el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                </div>
                <div style="width: 15%;height: 500px;">
                    <!-- <div style="width: 100%;height: 100px;background-color: #fff;">
                        <p style="text-align: center;padding-top: 20px;">
                            <img src="@/assets/img/icon/daojishi.png" alt="" srcset="" style="vertical-align: middle;">
                            <span style="padding-left: 10px;">剩余时间</span>
                        </p>
                        <p style="text-align: center;padding-top:10px;font-size: 24px;color: #76C8F6;">
                            00:58:56
                        </p>
                    </div> -->
                    <el-button type="primary" round style="background-color: #76C8F6;border: none;margin-left: 10px;margin-top: 200px;width: 100%;" @click="handpaper">交卷</el-button>
                </div>
            </div>
       
        </div>
        
        <el-dialog title="实训检验结果" v-model="dialogVisible" width="30%" :modal="false" :before-close="handleClose">
            <div style="height: 100px;background: #F7F8FA;padding: 10px;">
                <div style="display: flex;justify-content:space-around;">
                    <div>总题数</div>
                    <div>正确数</div>
                    <div>错误数</div>
                </div>
                <div style="display: flex;justify-content:space-around;margin-top: 20px;font-size: 20px;">
                    <div>{{WorkResults.allCount}}</div>
                    <div style="color:#76C8F6;">{{WorkResults.rightCount}}</div>
                    <div style="color: red;">{{WorkResults.errorCount}}</div>
                </div>
            </div>
            <p v-if="WorkResults.errorCount != 0" style="text-align: end;color: #76C8F6;padding-top: 5px;cursor: pointer;" @click="wrongQuestion">错题及解析</p>
            <div style="text-align: center;margin-top: 20px;">
                <el-button type="primary" round style="background-color: #76C8F6;border: none;margin-left: 10px;"
                    @click="handleClose">返回</el-button>
            </div>

        </el-dialog>
        <el-dialog title="错题及解析" v-model="wrongVisible" width="30%" :modal="false" :before-close="wrongVisibleClose">
            <div style="max-height:400px;overflow-y: scroll;">
                <div style="height: 100px;background: #F7F8FA;padding: 5px;margin-top: 10px;" v-for="(item,index) in WorkResultsList" :key="index">
                    <p style="padding-top: 5px;font-size: 16px;">{{item.testQuestionContent}}</p>
                    <p style="padding-top: 5px;font-size: 14px;"><span>您的答案：</span><span style="color: red;">{{
                        ISimportAnswer(item)    
                    }}</span></p>
                    <p style="padding-top: 5px;font-size: 14px;"><span>正确答案：</span><span style="color: #76C8F6;">{{
                        IScontent(item.answer)
                    }}</span></p>
                    <p style="padding-top: 5px;font-size: 14px;">解析：{{item.testQuestionAnalysis}}</p>
                </div>
            </div>
            
            <div
                style="display: flex;justify-content: center;width: 100%;height: 40px;align-items: center;margin-top: 10px;">
                <el-pagination layout="prev, pager, next" :total="WorkResultsList.length" 
                
                />
            </div>

            <div style="text-align: center;margin-top: 20px;">
                <el-button type="primary" round style="background-color: #76C8F6;border: none;margin-left: 10px;"
                    @click="wrongVisibleClose">我知道了</el-button>
            </div>

        </el-dialog>
    </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted, computed,watch ,} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getStorage, setStorage } from "@/js/common";
import {backUrl} from "@/js/util";
import {} from "@/js/homeListApi.js";
import {baseUrl} from '@/js/util.js';
import { ElMessage } from 'element-plus';
import { f_submit_courseExercises } from "@/js/homeListApi";
export default {
    setup () {
        const router = useRouter();
        const store = useStore();
        const state = reactive({
            baseUrl: baseUrl,
            userId: getStorage('userId'),
            assessment:getStorage("assessment"),
            jie:getStorage('jie'),
            input1:'',
            input2:'',
            input3:'',
            radio:'',
            checkList:[],
            radio2:'',
            dialogVisible: false,
            wrongVisible: false,
            exercisesnName:'',
            trainingName:'',
            Fillintheblanks: [],//填空
            SingleSelection: [],//单选
            Multiple: [],//多选
            Judgment: [],//判断

            WorkResults:{},
            WorkResultsList:[],


        })
        onMounted(()=>{
            console.log(state.assessment);
            state.exercisesnName = state.assessment.exercisesnName;
            state.trainingName = state.assessment.trainingName;
            // topicType 题类型 1单选 2多选 3填空 4判断
            state.assessment.questionList.forEach(item => {
                if (item.topicType == 1) {
                    state.SingleSelection.push({
                        answerList:item.answerList,
                        id:item.id,
                        score:item.score,
                        testQuestionContent:item.testQuestionContent,
                        topicType:item.topicType,
                        dev:''
                    })
                } else if (item.topicType == 2) {
                    state.Multiple.push({
                        answerList:item.answerList,
                        id:item.id,
                        score:item.score,
                        testQuestionContent:item.testQuestionContent,
                        topicType:item.topicType,
                        dev:[]
                    })
                } else if (item.topicType == 3) {
                    state.Fillintheblanks.push({
                        answerList:item.answerList,
                        id:item.id,
                        score:item.score,
                        testQuestionContent:item.testQuestionContent,
                        topicType:item.topicType,
                        dev:''
                    })
                } else if (item.topicType == 4) {
                    state.Judgment.push({
                        answerList:item.answerList,
                        id:item.id,
                        score:item.score,
                        testQuestionContent:item.testQuestionContent,
                        topicType:item.topicType,
                        dev:''
                    })
                }
            });
            console.log(state.Multiple);
        })
        const methods = {
            //
            handpaper() {
                    let i_SubmintQuestions = []
                    //填空
                    state.Fillintheblanks.forEach(item => {
                        let FillanswerIds = []
                        item.answerList.forEach((answerItem) => {
                            if (answerItem.dev?.trim()) {
                                FillanswerIds.push(answerItem.dev)
                            }
                        })
                        i_SubmintQuestions.push({
                            questionId: item.id,
                            answerIds: FillanswerIds.toString(),
                            score: item.score
                        })
                    });
                    //单选
                    state.SingleSelection.forEach(item => {
                        let SinganswerIds = []
                        if (item.dev) {
                            item.answerList.forEach((answerItem) => {
                                if (answerItem.isOrder == item.dev) {
                                    SinganswerIds.push(answerItem.id)
                                }
                            })
                        }
                        i_SubmintQuestions.push({
                            questionId: item.id,
                            answerIds: SinganswerIds.toString(),
                            score: item.score
                        })
                    });
                    //多选
                    // let i = 0;
                    state.Multiple.forEach(item => {
                        let MultanswerIds = [];
                        // i++
                        if (item.dev[0]) {
                            item.answerList.forEach((answerItem) => {
                                if (answerItem.isOrder == item.dev[0]) {
                                    MultanswerIds.push(answerItem.id)
                                }
                            })
                            // console.log(item.dev,'item.dev');
                        }
                        if (item.dev[1]) {
                            item.answerList.forEach((answerItem) => {
                                if (answerItem.isOrder == item.dev[1]) {
                                    MultanswerIds.push(answerItem.id)
                                }
                            })
                            // console.log(item.dev,'item.dev');
                        }
                        if (item.dev[2]) {
                            item.answerList.forEach((answerItem) => {
                                if (answerItem.isOrder == item.dev[2]) {
                                    MultanswerIds.push(answerItem.id)
                                }
                            })
                            // console.log(item.dev,'item.dev');
                        }
                        if (item.dev[3]) {
                            item.answerList.forEach((answerItem) => {
                                if (answerItem.isOrder == item.dev[3]) {
                                    MultanswerIds.push(answerItem.id)
                                }
                            })
                            // console.log(item.dev,'item.dev');
                        }
                        i_SubmintQuestions.push({
                            questionId: item.id,
                            answerIds: MultanswerIds.toString(),
                            score: item.score
                        })
                    });
                    //判断
                    state.Judgment.forEach(item => {
                        let JudganswerIds = []
                        if (item.dev) {
                            item.answerList.forEach((answerItem) => {
                                if (answerItem.isOrder == item.dev) {
                                    JudganswerIds.push(answerItem.id)
                                }
                            })
                        }
                        i_SubmintQuestions.push({
                            questionId: item.id,
                            answerIds: JudganswerIds.toString(),
                            score: item.score
                        })
                    });

                    let arr = [];
                    i_SubmintQuestions.forEach(item => {
                        if (item.answerIds.split(',')[0] == '') {
                            arr.push(1)
                        }
                    })
                    console.log(arr, 'arr');

                    if (arr.length != 0) {
                        ElMessage.error('有题未作答')
                        return
                    }

                    let datas = {
                        examinationId: state.assessment.examinationId,
                        studentId: getStorage('userId'),
                        i_SubmintQuestions: i_SubmintQuestions,
                    }
                    f_submit_courseExercises(datas).then((res)=>{
                        if (res.data.code == 1) {
                            ElMessage.success(res.data.message)
                            state.WorkResults = res.data.data;
                            state.WorkResultsList = res.data.data.list;
                            state.dialogVisible = true;
                            console.log(state.WorkResults,'state.WorkResults');
                            console.log(state.WorkResultsList,'state.WorkResultsList');
                        }else{
                            ElMessage.error(res.data.message)
                        }

                    })
                // console.log(i_SubmintQuestions,'i_SubmintQuestions');

                // state.dialogVisible = true;

            },

            IScontent(answer){
                let Arr = []
                answer.forEach((em)=>{
                    if (em.isAnswer==true) {
                        Arr.push(em.content)
                    }
                })
                return Arr.join(",")
            },
            ISimportAnswer(item){
                let text = []
                if (item.topicType == 3) {
                    text.push(item.answer2)
                } else {
                    item.importAnswer.forEach((em)=>{
                        text.push(em)
                    })
                }
                return text.join(",")
            },

            indexClick() {
                store.commit("SetnarbarMenuActive", 1);
                setStorage("narbarMenuActive", 1);
                router.push('/home/index');
            },
            DetClick(){
                router.push('/home/courseIntroductionDetails');
            },
            detailsClick(){
                router.push('/home/courseDetailsCopy');
            },
            courseClick() {
                router.push('/home/courseTeaching');
            },
            examineClick() {
                router.push('/home/examine')
            },
           
            handleClose() {
                router.push('/home/courseIntroductionDetails')
                state.dialogVisible = false;
            },
            // 错题解析
            wrongQuestion() {
                state.wrongVisible = true;
            },
            wrongVisibleClose() {
                state.wrongVisible = false;
            },
        }
        return{
            ...toRefs(state),
            ...methods,
        }
    }
}
</script>
<style  lang="scss" scoped>
#courseIntroductionDetails{
    height: 100%;
    position: relative;
    background: #F7F8FA;
    .box{
        width: 66.5%;
        height: 100%;
        margin: 0 auto;
    }
    .breadCrumbs{
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 12px;
    }
    .rightBox{
        width: 39%;
        height:800px;
        background: #fff;
        float: right;
        overflow-y: auto;
    }
    
   :deep .el-collapse-item__header{
        background: #F7F8FA;
    }
    :deep .el-collapse-item__wrap{
        border: none;
    }
    .titleSystemClass{
        width: 100%;
        height: 80px;
        background: #fff;
    }
    .system{
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
    }
    div::-webkit-scrollbar {
    width: 4px;
  }
  div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    opacity: 0.2;;
  }
  div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
  }

}
</style>